<template>
    <div class="create_account">
        <div class="left_area">
            <div class="signup_vid">
                <div class="video_wpr">
                    <iframe src="https://player.vimeo.com/video/798481517?color&autopause=0&loop=0&muted=0&title=0&portrait=0&byline=0#t=" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
            </div>
            <div class="logo">
                <img src="@/assets/images/logo2.svg" alt="Logo">
            </div>
            <div class="area_content">
                <h2>Create Your Account</h2>
                <h6>You'll be up &amp; running in 2 minutes</h6>
                <div class="g_sign mt-5 mb-5">
                    <google-login :callback="googleAuthCallback" prompt auto-login>
                        <button type="button" :disabled="accountCreateLoader || googleAuthLoader" class="primary_btn">
                            <template v-if="googleAuthLoader">
                                <i class="fa fa-spin fa-spinner prefix"></i><span>Processing</span>
                            </template>
                            <template v-else>
                                <i class="fab fa-google prefix"></i><span>Continue With Google</span>
                            </template>
                        </button>
                    </google-login>
                </div>
                <h5><span>Or register with your email</span></h5>
                <Form @submit="handleRegister" class="create_form" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">First Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.first_name }">
                                    <Field autocomplete="off" type="text" name="first_name" v-model="form.first_name" placeholder="John" rules="required" label="first name" />
                                </div>
                                <ErrorMessage name="first_name" class="text-danger" />
                            </div>
                            <div class="group_item">
                                <label class="input_label">Last Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.last_name }">
                                    <Field autocomplete="off" type="text" name="last_name" v-model="form.last_name" placeholder="Doe" rules="required" label="last name" />
                                </div>
                                <ErrorMessage name="last_name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">E-mail</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.email }">
                                    <Field autocomplete="off" type="email" name="email" v-model="form.email" placeholder="johndoe@thrivecoach.io" rules="required|email" />
                                </div>
                                <ErrorMessage name="email" class="text-danger" />
                            </div>
                        </div>
                        <div class="create_btn mt-5">
                            <button class="primary_btn" :disabled="accountCreateLoader || googleAuthLoader">
                                <template v-if="accountCreateLoader && !googleAuthLoader">
                                    <i class="fa fa-spin fa-spinner"></i>&nbsp; Processing
                                </template>
                                <template v-else>
                                    <span>CONTINUE</span><i class="fas fa-long-arrow-alt-right suffix"></i>
                                </template>
                            </button>
                            <div class="login_btn">
                                Have an Account?
                                <router-link :to="{ name: 'Login' }">Log in</router-link>
                                here
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        <div class="right_area">
            <div class="signup_vid">
                <div class="video_wpr">
                    <iframe src="https://player.vimeo.com/video/798481517?color&autopause=0&loop=0&muted=0&title=0&portrait=0&byline=0#t=" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
            </div>
            <h1>The All-In-One Command Center For Coaches That Work With Clients!</h1>
            <ul class="brands">
                <li><img src="@/assets/images/brand-1.png" alt=""></li>
                <li><img src="@/assets/images/brand-2.png" alt=""></li>
                <li><img src="@/assets/images/brand-7.png" alt=""></li>
                <li><img src="@/assets/images/brand-8.png" alt=""></li>
                <li><img src="@/assets/images/brand-5.png" alt=""></li>
                <li><img src="@/assets/images/brand-6.png" alt=""></li>
                <li><img src="@/assets/images/brand-4.png" alt=""></li>
                <li><img src="@/assets/images/brand-3.png" alt=""></li>
                <li><img src="@/assets/images/brand-9.png" alt=""></li>
                <li><img src="@/assets/images/brand-11.png" alt=""></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { OAuth2Client } from 'google-auth-library'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    const client  = new OAuth2Client({
                        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                        clientSecret: process.env.VUE_APP_GOOGLE_CLIENT_SECRET,
                        redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT_URI
                    });

    async function verifyCode (code) {
        let { tokens } = await client.getToken(code);

        client.setCredentials({ access_token: tokens.access_token });

        const userinfo = await client.request({ url: 'https://www.googleapis.com/oauth2/v3/userinfo' });

        return userinfo.data
    }

    export default {
        name: 'Create Account',

        data () {
            return {
                form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    plan: '',
                },
                googleAuthLoader: false,
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        computed: mapState({
            accountCreateLoader: state => state.registerModule.accountCreateLoader,
        }),

        mounted () {
            const vm = this;

            if (vm.$route.query) {
                if (vm.$route.query.plan) {
                    vm.form.plan = vm.$route.query.plan;
                }

                if (vm.$route.query.first_name) {
                    vm.form.first_name = vm.$route.query.first_name;
                }

                if (vm.$route.query.last_name) {
                    vm.form.last_name = vm.$route.query.last_name;
                }

                if (vm.$route.query.email) {
                    vm.form.email = vm.$route.query.email;
                }
            }

            setTimeout(function () {
                const gist = window.gist;

                if (gist && process.env.VUE_APP_ENV == 'production') {
                    gist.chat('shutdown');
                }
            }, 500);
        },

        methods: {
            ...mapActions({
                registerUser: 'registerModule/registerUser',
            }),

            handleRegister (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                const referralToken = vm.$cookies.get('referral_token');

                params.setFieldError = setFieldError;

                if (vm.$route.query && vm.$route.query.plan) {
                    params.plan = vm.$route.query.plan;
                }

                if (referralToken) {
                    params.referral_token = referralToken;
                }

                vm.registerUser(params).then((result) => {
                    if (result) {
                        vm.$router.push({name: 'VerifyAccount', params: { token: result.token }, query: { plan: vm.$route.query.plan }});
                    }
                });
            },

            googleAuthCallback (resp) {
                const vm = this;

                vm.googleAuthLoader = true;

                verifyCode(resp.code).then((user) => {
                    const referralToken = vm.$cookies.get('referral_token');
                    const params = {
                        first_name: user.given_name,
                        last_name: user.family_name,
                        email: user.email,
                        google_auth: true,
                    };

                    if (vm.$route.query && vm.$route.query.plan) {
                        params.plan = vm.$route.query.plan;
                    }

                    if (referralToken) {
                        params.referral_token = referralToken;
                    }

                    vm.registerUser(params).then((result) => {
                        if (result) {
                            vm.$router.push({name: 'VerifyAccount', params: { token: result.token }, query: { plan: vm.$route.query.plan }});
                        }

                        vm.googleAuthLoader = false;
                    });
                }).catch((error) => {
                    const options = Helper.swalWarningOptions('Oops!', 'Looks like something went wrong, please try after some time.');

                    Swal.fire(options);
                    vm.googleAuthLoader = false;
                })
            },
        },
    }
</script>

<style scoped>
    .create_account {
        height: 100vh;
        display: flex;
        flex-grow: 1;
        width: auto;
        align-items: stretch;
        margin: 0 -30px;
    }

    .create_account .left_area {
        max-width: 500px;
        min-height: 100vh;
        overflow-y: auto;
        padding: 30px 50px;
        border-right: 1px solid #e9e9e9;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .create_account .left_area::-webkit-scrollbar{
        display: none;
    }

    .create_account .left_area .logo {
        margin: 20px 0 40px;
    }

    .create_account .left_area .logo img {
        max-width: 180px;
        height: auto;
        width: 100%;
    }

    .create_account .left_area .area_content {
        margin: auto 0;
        text-align: left;
    }

    .create_account h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        color: #121525;
        margin-bottom: 10px;
    }

    .create_account h6 {
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .create_account h6 a {
        display: flex;
        text-decoration: none;
        justify-content: flex-end;
    }

    .create_account h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        text-align: center;
        position: relative;
    }

    .create_account h5:after {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        width: 100%;
        border-bottom: 1px solid #d9d9d9;
        z-index: 0;
    }

    .create_account h5 span {
        position: relative;
        z-index: 1;
        padding: 0 15px;
        background: #fafaf5;
    }

    .create_account .primary_btn {
        position: relative;
        height: 50px;
        cursor: pointer;
    }

    .create_account .primary_btn span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    .create_account .primary_btn .prefix {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
    }

    .create_account .primary_btn .fa-spinner.prefix {
        position: absolute;
        left: 30px;
        top: 30%;
        transform: translateY(-50%);
    }

    .create_account .primary_btn .suffix {
        position: absolute;
        right: 70px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }

    .create_account .login_btn {
        display: flex;
        justify-content: flex-end;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 20px 0;
    }

    .create_account .login_btn a {
        text-decoration: none;
        color: #2f7eed;
        padding: 0 5px;
    }

    .create_form {
        padding: 30px 0 0;
    }

    .create_account .right_area {
        flex: 1 1 auto;
        /* background: linear-gradient(35deg, #b318ff, #001eb7); */
        /* background: linear-gradient(35deg, #f2a31d, #2f7eed); */
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 35px 20px;
    }

    .create_account .right_area h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 600;
        color: #666;
        max-width: 600px;
    }

    .create_account .right_area .brands {
        padding: 60px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 20px;
        column-gap: 30px;
        max-width: 700px;
    }

    .create_account .right_area .brands li img {
        max-height: 30px;
        opacity: 0.6;
    }

    :deep(.g-btn-wrapper) {
        width: 100%;
    }

    .signup_vid{
        max-width: 600px;
        width: 100%;
        margin-bottom: 70px;
    }
    .signup_vid .video_wpr{
        width: 100%;
        padding-bottom: 56.25%;
        position: relative;
    }
    .signup_vid .video_wpr iframe{
        border-radius: 5px;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .left_area .signup_vid{
        margin: 0 auto 30px auto;
        display: none;
    }

    @media(max-width: 1399px){
        .create_account .right_area h1{
            font-size: 25px;
            line-height: 35px;
            max-width: 500px;
        }
        .create_account .right_area .brands{
            max-width: 550px;
            row-gap: 15px;
            column-gap: 22px;
            padding-top: 45px;
        }
        .create_account .right_area .brands li img{
            max-height: 20px;
        }
    }
    @media(max-width: 1199px){
        .create_account .right_area h1{
            font-size: 20px;
            line-height: 28px;
            max-width: 400px;
        }
        .create_account .right_area .brands{
            max-width: 450px;
            row-gap: 12px;
            column-gap: 18px;
            padding-top: 35px;
        }
        .create_account .right_area .brands li img{
            max-height: 17px;
        }
    }
    @media(max-width: 991px){
        .create_account{
            position: relative;
            margin-top: -110px;
        }
        .create_account .right_area{
            min-width: 380px;
        }
        .create_account .right_area h1{
            max-width: 350px;
        }
        .create_account .right_area .brands{
            max-width: 350px;
            row-gap: 12px;
            padding-top: 35px;
        }
    }
    @media(max-width: 767px){
        .create_account{
            flex-direction: column;
            margin: -110px -20px 0 -20px;
        }
        .create_account .right_area{
            min-width: auto;
            display: none;
        }
        .create_account .left_area{
            max-width: 100%;
            padding: 25px 40px;
        }
        .left_area .signup_vid{
            display: block;
        }
        .create_account .left_area .logo{
            margin: 10px auto 50px auto;
        }
        .create_account .left_area .area_content{
            margin: 0 auto;
            text-align: center;
        }
        .create_account .right_area h1{
            font-size: 16px;
            line-height: 25px;
            max-width: 300px;
        }
        .create_account .right_area .brands{
            max-width: 350px;
            row-gap: 10px;
            padding-top: 25px;
        }
        .create_account .right_area .brands li img{
            max-height: 13px;
        }
        .create_account .login_btn{
            justify-content: center;
        }
    }
    @media(max-width: 499px){
        .create_account .left_area .logo{
            margin: 10px auto 40px auto;
        }
    }
</style>
